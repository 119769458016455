import Image from 'next/image'
import React from 'react'

const Devider = () => {
  return (
    <div className='flex flex-row items-center justify-center my-6'>
        <div className=' w-2/4  '>
            <Image src={'/Rectangle 41.webp'} alt="devider rectangle" width={1} height={1} className='h-0.5 w-full' />
        </div>  
        <div className=' justify'>
            <Image src={'/Frame 55.svg'} alt="devider frame" width={25}height={20} />
        </div>
        <div className=' w-2/4  '>
            <Image src={'/Rectangle 42.webp'} alt="devider rectangle" width={1} height={1} className='h-0.5 w-full' />
        </div>
    </div>
  )
}

export default Devider